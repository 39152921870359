import useApiClient, { useRequestInit } from './apiClient';
import type { DashboardEntry } from '@wision/user-service/src/features/dashboard/model';
import type { Widget } from '@wision/user-service/src/features/widget/model';
import type { SelectedFilters } from '@wision/device-service/src/features/common/filter/model';

const BASE_SALT = 1000;

type ClientDashboard = DashboardEntry & { id: string, filter: SelectedFilters };

export const useDashboardApi = (
  baseUrl: string,
  ssr = false,
  sessionCookie: string | null = null
) => {
  const apiClient = useApiClient(ssr);
  const getRequestInit = useRequestInit(ssr, sessionCookie);

  const idEncode = (id: number): string => btoa(String(id + BASE_SALT)).replace(/={1,2}$/, '');
  const hashDecode = (hash: string): number => Number(atob(hash)) - BASE_SALT;

  const cacheKeys = {
    dashboards: 'dashboards'
  };

  const getAllDashboards = async (excludeContent = false, useContext = false,) => {
    const response = await apiClient<[DashboardEntry]>(
      `${baseUrl}/api/v3/user/dashboard?excludeContent=${excludeContent}&useContext=${useContext}`,
      getRequestInit({
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      })
    );

    const [dashboards, error] = response;
    const modified = dashboards.map(dashboard =>
      ({ ...dashboard, id: idEncode(dashboard.id) }));

    return [modified, error] as [Array<ClientDashboard>, boolean];
  };

  const getDashboard = async (hash: string) => {
    const [dashboard, error] = await apiClient<ClientDashboard>(
      `${baseUrl}/api/v3/user/dashboard/${hashDecode(hash)}`,
      getRequestInit({
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      })
    );

    return [{ ...dashboard, id: idEncode(dashboard.id) }, error] as [ClientDashboard, boolean];
  };

  const deleteDashboard = async (hash: string) =>
    await apiClient<unknown>(
      `${baseUrl}/api/v3/user/dashboard/${hashDecode(hash)}`,
      getRequestInit({
        method: 'DELETE',
        headers: {
          Accept: 'text/plain',
        },
      }),
      false
    );

  const setDashboard = async (hash: string, dashboard: unknown) =>
    await apiClient<unknown>(
      `${baseUrl}/api/v3/user/dashboard/${hashDecode(hash)}`,
      getRequestInit({
        method: 'PUT',
        body: JSON.stringify(dashboard),
        headers: {
          Accept: 'text/plain',
        },
      }),
      false
    );

  const createDashboard = async (name: string, templateId?: string, useContext = false) => {
    const [dashboard, error] = await apiClient<DashboardEntry>(
      `${baseUrl}/api/v3/user/dashboard`,
      getRequestInit({
        method: 'POST',
        body: JSON.stringify({ 
          name: name, 
          templateId: templateId ,
          useContext: useContext
        }),
        headers: {
          'Content-Type': 'application/json',
          Accept: 'text/plain',
        },
      })
    );

    return [{ ...dashboard, id: idEncode(dashboard.id) }, error] as [ClientDashboard, boolean];
  };

  const getTemplates = async () =>
    await apiClient<{ id: string, name: string }[]>(
      `${baseUrl}/api/v3/user/dashboard/template`,
      getRequestInit({
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'text/plain',
        },
      })
    );

  const getWidgets = async () =>
    await apiClient<Widget[]>(
      `${baseUrl}/api/v3/user/widget`,
      getRequestInit({
        method: 'GET',
        headers: {
          Accept: 'text/plain',
        },
      })
    );

  const updateName = async (name: string, id: string) => 
    await apiClient<Widget[]>(
      `${baseUrl}/api/v3/user/dashboard/${hashDecode(id)}/updateName`,
      getRequestInit({
        method: 'PUT',
        body: JSON.stringify({ name, id }),
        headers: {
          'Content-Type': 'application/json',
          Accept: 'text/plain',
        },
      })
    );

  const updateFilter = async (filter: SelectedFilters, id: string) => 
    await apiClient<void>(
      `${baseUrl}/api/v3/user/dashboard/${hashDecode(id)}/filter`,
      getRequestInit({
        method: 'PUT',
        body: JSON.stringify(filter),
        headers: {
          'Content-Type': 'application/json',
          Accept: 'text/plain',
        },
      })
    );

  return {
    cacheKeys,
    getall: getAllDashboards,
    set: setDashboard,
    get: getDashboard,
    delete: deleteDashboard,
    create: createDashboard,
    getWidgets: getWidgets,
    getTemplates,
    updateName,
    updateFilter
  };
};
