export default defineNuxtPlugin(() => {
  const LOGIN_PATH = '/login';
  const { fetch: originalFetch } = window;

  window.fetch = async (...args) => {
    const response = await originalFetch(...args);

    if (!response.ok && response.status == 401)
      navigateTo(LOGIN_PATH);

    return response;
  };
});
