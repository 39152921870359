import useApiClient, { useRequestInit } from './apiClient';
import type { Camera, Image } from '@core-service/service/camera';

export const useCameraApi = (
  baseUrl: string,
  ssr = false,
  sessionCookie: string | null = null
) => {
  const apiClient = useApiClient(ssr);
  const getRequestInit = useRequestInit(ssr, sessionCookie);

  const getCameras = async () => 
    await apiClient<Camera[]>(
      `${baseUrl}/api/v2/camera/cameras`, 
      getRequestInit({
        method: 'GET',
        headers: {
          Accept: 'application/json',
        },
      })
    );

  const getImages = async (id: string) => 
    await apiClient<Image[]>(
      `${baseUrl}/api/v2/camera/cameras/${id}/images`,  
      getRequestInit({
        method: 'GET',
        headers: {
          Accept: 'application/json',
        },
      })
    );

  return {
    getCameras,
    getImages
  };
};
