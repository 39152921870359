import useApiClient, { useRequestInit } from './apiClient';
import { GetWeatherResponse } from '@wision/api-client-v1/src/responses';

export const useWeatherApi = (
  baseUrl: string,
  ssr = false,
  sessionCookie: string | null = null
) => {
  const apiClient = useApiClient(ssr);
  const getRequestInit = useRequestInit(ssr, sessionCookie);

  const getWeatherFromLocation = async (lat: string, long: string, lang = 'en', days = 5) =>
    await apiClient<GetWeatherResponse[]>(
      `${baseUrl}/api/v1/weather/getWeather?lat=${lat}&lon=${long}&days=${days}&lang=${lang}`,
      getRequestInit({
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      })
    );

  return {
    getWeatherFromLocation
  };
};
