/**
 * @overview General utils for UI
 */
import enUS from 'date-fns/locale/en-US/index';
import sv from 'date-fns/locale/sv/index';
import de from 'date-fns/locale/de/index';
import da from 'date-fns/locale/da/index';
import { type Component, createApp, shallowRef } from 'vue';
import { produce, Draft } from 'immer';

export const selectedFilterUnits = (filterUnits: Array<string>, newValue: string) => {
  if (filterUnits && !filterUnits.includes(newValue)) {
    filterUnits.push(newValue);
  } else if (filterUnits) {
    const index = filterUnits.indexOf(newValue);
    if (index !== -1) {
      filterUnits.splice(index, 1);
    }
  } else {
    filterUnits = [newValue];
  }

  return filterUnits;
};

export const getFormattedTime = (timestamp: string): string | null => {
  const date = new Date(timestamp);

  if (date.getFullYear() < 1901) return null;

  return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
};

export const getDateFnLocale = (locale: string) => {
  switch (locale) {
  case 'sv':
    return sv;
  case 'de':
    return de;
  case 'da':
    return da;
  default:
    return enUS;
  }
};

export const createComponent = (component: Component, props: Record<string, unknown>) => {
  const app = createApp(component, props);
  const content = document.createElement('div');
  app.mount(content);

  return content;
};

export const useImmer = <T>(baseState: T) => {
  const state = shallowRef({ ...baseState });
  const update = (updateFn: (draft: Draft<T>) => void) => {
    state.value = produce<T>(state.value, updateFn);
  };
  const setter = <K extends keyof T, V extends T[K]>(key: K) =>
    (value: V) => update(draft => {
      return {
        ...draft,
        [key]: value
      };
    });

  /* state: current state
   * update: function used for updating current state from a draft
   * setter: function for creating a setter function for a specific key in the state */
  return [state, update, setter] as const;
};

