import { useOperationsApi } from '@wision/ui';
import {
  withUseQuery,
  type Operation,
} from '@wision/api';

export default defineNuxtPlugin(() => {
  const apiClient = useApi();
  const { $i18n } = useNuxtApp();

  const triggerOperationApi = (deviceId: string, operation: Operation) =>
    withUseQuery(() => apiClient.device.triggerOperation(deviceId, operation));

  const pollOperationApi = (
    deviceId: string,
    sequence: number,
    operation: Operation
  ) =>
    withUseQuery(() =>
      apiClient.device.pollOperationStatus(deviceId, sequence, operation)
    );

  const { state, trigger } = useOperationsApi(
    triggerOperationApi,
    pollOperationApi,
    $i18n.t
  );

  return {
    provide: {
      operations: {
        state,
        trigger
      }
    }
  };
});
