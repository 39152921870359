import {
  useDashboardApi,
  useUserApi,
  useDeviceApi,
  useAuthApi,
  useAdminApi,
  useWeatherApi,
  useCameraApi
} from '@wision/api';

export default defineNuxtPlugin(() => {
  const config = useRuntimeConfig();
  const session = useCookie('session');
  const params = [config.public.apiHost, process.server, session.value] as const;

  const dashboard = useDashboardApi(...params);
  const user = useUserApi(...params);
  const auth = useAuthApi(...params);
  const admin = useAdminApi(...params);
  const weather = useWeatherApi(...params);
  const device = useDeviceApi(config.public.apiHost, 2, process.server, session.value);
  const deviceV3 = useDeviceApi(config.public.apiHost, 3, process.server, session.value);
  const camera = useCameraApi(config.public.apiHost, process.server, session.value);

  return {
    provide: {
      api: {
        admin,
        auth,
        dashboard,
        device,
        deviceV3,
        user,
        weather,
        camera
      }
    }
  };
});
