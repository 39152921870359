import revive_payload_client_YTGlmQWWT5 from "/home/runner/work/wision/wision/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_neSs9z3UJp from "/home/runner/work/wision/wision/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_VeD7Ba96AP from "/home/runner/work/wision/wision/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_VTarsGAzgb from "/home/runner/work/wision/wision/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_PKIXU5KmsI from "/home/runner/work/wision/wision/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_LIYcCMJD18 from "/home/runner/work/wision/wision/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import view_transitions_client_HXqymDRHmq from "/home/runner/work/wision/wision/node_modules/nuxt/dist/app/plugins/view-transitions.client.js";
import chunk_reload_client_SeG0EjL5Ec from "/home/runner/work/wision/wision/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/home/runner/work/wision/wision/apps/wision/.nuxt/components.plugin.mjs";
import prefetch_client_8FoqvPFTot from "/home/runner/work/wision/wision/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import i18n_dOTnCc6TUQ from "/home/runner/work/wision/wision/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import api_client_KiyocXJS6U from "/home/runner/work/wision/wision/apps/wision/src/plugins/api-client.ts";
import auth_interceptor_client_29YeIGEGt1 from "/home/runner/work/wision/wision/apps/wision/src/plugins/auth-interceptor.client.ts";
import operations_client_GTqtCQSApu from "/home/runner/work/wision/wision/apps/wision/src/plugins/operations.client.ts";
import sentry_h0fMjCG9AB from "/home/runner/work/wision/wision/apps/wision/src/plugins/sentry.ts";
import toast_client_5GxzM6P4QL from "/home/runner/work/wision/wision/apps/wision/src/plugins/toast.client.ts";
import vue_query_JC9gnf7CA1 from "/home/runner/work/wision/wision/apps/wision/src/plugins/vue-query.ts";
export default [
  revive_payload_client_YTGlmQWWT5,
  unhead_neSs9z3UJp,
  router_VeD7Ba96AP,
  payload_client_VTarsGAzgb,
  navigation_repaint_client_PKIXU5KmsI,
  check_outdated_build_client_LIYcCMJD18,
  view_transitions_client_HXqymDRHmq,
  chunk_reload_client_SeG0EjL5Ec,
  components_plugin_KR1HBZs4kY,
  prefetch_client_8FoqvPFTot,
  i18n_dOTnCc6TUQ,
  api_client_KiyocXJS6U,
  auth_interceptor_client_29YeIGEGt1,
  operations_client_GTqtCQSApu,
  sentry_h0fMjCG9AB,
  toast_client_5GxzM6P4QL,
  vue_query_JC9gnf7CA1
]